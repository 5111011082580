import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import GUI from "lil-gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { gsap } from "gsap";
/**
 * Base
 */
// Debug
// const gui = new GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Models
 */
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
let sketchfabModel = null;
let sketchfabModel001 = null;
let clickableMeshes = [];
const gltfLoader = new GLTFLoader();
let model;
const BLOOM_LAYER = 1; // Custom layer for bloom
gltfLoader.load("/models/buda14.glb", (gltf) => {
  model = gltf.scene;
  //   gltf.scene.scale.set(0.025, 0.025, 0.025);
  //   gltf.scene.position.z -= 4;
  gltf.scene.position.set(0, -40, -200); // Adjust to your desired starting position

  gltf.scene.traverse((child) => {
    if (child.name === "Empty") {
      // child.visible = false;
      // 'Empty' parent found, now apply changes to its children
      child.material = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        emissive: new THREE.Color(0xffff00), // Yellow emissive glow
        emissiveIntensity: 100, // Increase intensity for strong bloom
      });
    }
  });
  model.traverse((child) => {
    if (child.isMesh) {
      // Log child names for debugging
      console.log("Child Name:", child.name);

      // Add specific meshes to the clickable array
      if (
        child.name === "Object_4" || // First clickable mesh
        child.name === "Object_4001" ||
        child.name === "Object_5001" ||
        child.name === "Object_4002" ||
        child.name === "Object_5002" ||
        child.name === "Object_6" ||
        child.name === "Object_7" ||
        child.name === "Object_5" || // Second clickable mesh
        child.name === "Hedra001_Material_#0_0" // Third clickable mesh
      ) {
        clickableMeshes.push(child);
      }
    }
  });

  console.log("Clickable Meshes:", clickableMeshes);

  scene.add(gltf.scene);

  controls.enabled = false;

  const tl = gsap.timeline();
  gsap.to(bloomPass, {
    strength: 1, // Final adjustment to ensure 3
    delay: 3, // Delay the animation by 2 seconds
    duration: 6, // Duration in seconds
    ease: "power2.out",
  });
  // Move the model to its final position
  tl.to(gltf.scene.position, {
    z: 0, // Move the model closer to the origin
    y: -20, // Center vertically
    delay: 2, // Delay the animation by 2 seconds
    duration: 6, // Duration in seconds
    ease: "power2.out", // Smooth easing function

    onComplete: () => {
      // Enable controls after animation
      controls.enabled = true;
      gsap.to(bloomPass, {
        strength: 1, // Final adjustment to ensure 3
        duration: 0.5, // Smooth transition over 1 second
        ease: "power2.out",
      });
    },
  });
});

// Handle Mouse Click
/**
 * Helper: Get All Meshes from an Object
 */

/**
 * Mouse Click Handling
 */
// Add Event Listeners
function handleInteraction(event) {
  let clientX, clientY;

  if (event.touches) {
    // Touch event
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  } else {
    // Mouse event
    clientX = event.clientX;
    clientY = event.clientY;
  }

  // Normalize coordinates
  mouse.x = (clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(clientY / window.innerHeight) * 2 + 1;

  // Update raycaster
  raycaster.setFromCamera(mouse, camera);

  // Check intersections
  const intersects = raycaster.intersectObjects(clickableMeshes, true);

  if (intersects.length > 0) {
    const clickedObject = intersects[0].object;

    // Handle interaction with specific objects
    if (clickedObject.name === "Object_4") {
      console.log("Interacted with Object_4");
      openLink("https://x.com/TheBuddhAi");
    } else if (clickedObject.name === "Object_5") {
      console.log("Interacted with Object_5");
      openLink("https://x.com/TheBuddhAi");
    } else if (clickedObject.name === "Hedra001_Material_#0_0") {
      console.log("Interacted with Hedra001_Material_#0_0");
      openLink("https://useswarm.ai/agents/TheBuddhAi");
    } else if (clickedObject.name === "Object_4001") {
      console.log("Interacted with Object_4001");
      openLink("https://t.me/TheBuddhAi");
    } else if (clickedObject.name === "Object_5001") {
      console.log("Interacted with Object_5001");
      openLink("https://t.me/TheBuddhAi");
    } else if (clickedObject.name === "Object_5002") {
      openLink("https://www.tiktok.com/@thebuddhai");
    } else if (clickedObject.name === "Object_4002") {
      openLink("https://www.tiktok.com/@thebuddhai");
    } else if (clickedObject.name === "Object_6") {
      openLink("https://www.tiktok.com/@thebuddhai");
    } else if (clickedObject.name === "Object_7") {
      openLink("https://www.tiktok.com/@thebuddhai");
    }
  } else {
    console.log("No object interacted with.");
  }
}

function isMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

// Function to open a link conditionally
function openLink(url) {
  if (isMobileDevice()) {
    // Open in the same tab for mobile devices
    window.location.href = url;
  } else {
    // Open in a new tab for desktops
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank"; // Open in a new tab
    a.rel = "noopener noreferrer"; // Security best practices
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
// Add combined event listeners
window.addEventListener("click", handleInteraction);
window.addEventListener("touchstart", handleInteraction);
/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 2.4);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(-5, 5, 0);
scene.add(directionalLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  60,
  sizes.width / sizes.height,
  0.1,
  3000
);
camera.position.set(0, 0, 3);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.target.set(0, 0, 0);
controls.enableDamping = true; // Enable smooth damping for smooth transitions
controls.dampingFactor = 0.05; // Adjust damping for smoothness
controls.enableZoom = false; // Disable zoom
controls.enablePan = false; // Disable panning

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// renderer.setClearColor(oxffffff);
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(0x020202, 1); // Black background with full opacity

const composer = new EffectComposer(renderer);

// Render pass (renders the scene normally)
const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);

// Bloom pass
const bloomPass = new UnrealBloomPass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  1.0, // Bloom strength
  0.6, // Bloom radius
  0.1 // Bloom threshold
);
composer.addPass(bloomPass);

// Adjust bloom parameters using GUI
// gui.add(bloomPass, "strength").min(0).max(3).step(0.01).name("Bloom Strength");
// gui.add(bloomPass, "radius").min(0).max(1).step(0.01).name("Bloom Radius");
// gui
//   .add(bloomPass, "threshold")
//   .min(0)
//   .max(1)
//   .step(0.01)
//   .name("Bloom Threshold");

/**
/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;
let distance;
const tick = () => {
  if (model) {
    const distance = camera.position.distanceTo(model.position);

    // Dynamically adjust bloom strength for distance
    // Retain the higher bloom value if animation has finished
    if (bloomPass.strength < 3) {
      // bloomPass.strength += clock.getElapsedTime(); // Adjust multiplier and range
    }
  }

  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;
  // Dynamically adjust bloom strength based on distance

  // Update controls
  controls.update();
  composer.render();

  // Render
  // renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
